var botoes = document.querySelectorAll('.btn-toggle');

/*FOR MARAVILINDO*/
botoes.forEach(function(btn) {
	var seletor = btn.getAttribute('data-target');

	if(seletor) {

		var alvos 	= document.querySelectorAll(seletor);

		btn.addEventListener('click', function() {
			alvos.forEach(function(alvo) {
				alvo.classList.toggle('active');
			})
			this.classList.toggle('active');
			$('.bg-toggle').fadeIn(500);
		})

		$('.bg-toggle').click(function(){
			$(alvos).removeClass('active');
			$('.btn-toggle').removeClass('active');

			$(this).fadeOut(500);
		})
	}
});