$('[data-botoes-toggle="true"] button').click(function(e){
	e.preventDefault();

	let target = $(this).data('target');
	let value = $(target).val();

	let fn = $(this).data('fn');

	if(isNaN(value)) return;

	value = parseInt(value);

	switch(fn){
		case 'minus':
			if(value > 1){
				$(target).val(value -1).trigger('change')
			}
		break;

		case 'plus':
			$(target).val(value +1).trigger('change')
		break;
	}

	$(this).blur();
});